import React from "react";
import { Link } from "react-router-dom";
import phoneImage from "../assets/image-cel.png";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    root: {},
    content: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "5%",
        textAlign: "center",
        paddingTop: "175px",
    },
    phone: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
    },
    title: {
        fontWeight: "900",
        color: "#0A5359",
        fontSize: "55px",
        textTransform: "uppercase",
    },
    text: {
        color: "#0A5359",
        opacity: "0.75",
        fontSize: "20px",
    },
}));

export default function SlideShow() {
    const classes = useStyles();
    return (
        <section className="landing" id="inicio">
            <Grid container>
                <Grid item md={6} xs={12} className={classes.content}>
                    <h1 className={classes.title}>
                        Paga de manera<br></br>fácil y segura
                    </h1>
                    <p className={classes.text}>
                        Con MOVILPLATA, realiza tus<br></br>transacciones, pagos
                        y compras<br></br>de manera practica desde tu celular.
                    </p>
                    <Link to="/login" className="btn">
                        Bienvenidos
                    </Link>
                </Grid>
                <Grid
                    item
                    md={6}
                    xs={12}
                    className={classes.phone}
                    id="imageSlideShow"
                >
                    <img src={phoneImage} width="80%" alt="movilplata-logo" />
                </Grid>
            </Grid>
        </section>
    );
}
