import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Page from '../../../helpers/Page'
import Header from '../../../helpers/Header'
import {
  useHistory
} from "react-router-dom";
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  TextField,
  colors,
} from '@material-ui/core';
import {Visibility, VisibilityOff} from '@material-ui/icons';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import { toast } from 'react-toastify';
import AuthService from '../../../services/AuthService'


const useStyles = makeStyles(theme => ({
  root: {},
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  TopMargin: {
    marginTop: theme.spacing(3)
  },
}));

const schema = yup.object({
  oldPass: yup.string().required('Este campo es requerido'),
  newPass: yup.string().required('Este campo es requerido').min(8,'La minima longitud es 8 caractares'),
  confirmPass: yup.string().required('Este campo es requerido').min(8,'La minima longitud es 8 caractares').oneOf([yup.ref('newPass')], 'La nueva contraseña no coincide'),
});

const ChangePass = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const userData = props.userData ? props.userData : {}
  const { register, handleSubmit, formState:{ errors }, reset } = useForm({
    resolver: yupResolver(schema)
  });
  const [isLoading, setLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  
  const navigate = useHistory();

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const onSubmit = data => { 
    let authService = new AuthService();
    let UserData = userData;
    // console.log(data)
    
    authService.ChangePassService(data.oldPass, data.newPass).then((response) => {
      if(response.description === "Success"){
        setLoading(!isLoading)
        toast.success("¡Contraseña cambiada!");
        reset()
        navigate.push("/admin/home")
      } else {
        reset()
        toast.error(response.description);  
      }  
    })
  }

  return (
    <Page
      className={classes.root}
      title="Cambio de contraseña - Movilplata"
    >
      <Header mainTitle='Movilplata' subTitle='Cambio de contraseña.'/>
      <Card
        {...rest}
        className={clsx(classes.root, className, classes.TopMargin)}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <CardHeader title="Completa la informacion, para cambiar de contraseña." />
          <Divider />
          <CardContent>
            <Grid
              container
              spacing={4}
            >
              <Grid
                item
                md={12}
                xs={12}
              >
                <InputLabel htmlFor="old-password">Contraseña actual</InputLabel>
                <Input
                  fullWidth
                  required
                  id="old-password"
                  type={showPassword ? 'text' : 'password'}
                  variant="outlined"
                  name="oldPass"
                  {...register("oldPass")}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {errors.oldPass && <p className="invalid-feedback d-block">{errors.oldPass?.message}</p>}
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <InputLabel htmlFor="new-password">Nueva contraseña</InputLabel>
                <Input
                  fullWidth
                  required
                  id="new-password"
                  type={showPassword ? 'text' : 'password'}
                  variant="outlined"
                  name="newPass"
                  {...register("newPass")}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {errors.newPass && <p className="invalid-feedback d-block">{errors.newPass?.message}</p>}
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <InputLabel htmlFor="confirm-password">Confirmar contraseña</InputLabel>
                <Input
                  fullWidth
                  required
                  id="confirm-password"
                  type={showPassword ? 'text' : 'password'}
                  variant="outlined"
                  name="confirmPass"
                  {...register("confirmPass")}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                      >
                        {showPassword ? <Visibility /> : <VisibilityOff />}
                      </IconButton>
                    </InputAdornment>
                  }
                />
                {errors.confirmPass && <p className="invalid-feedback d-block">{errors.confirmPass?.message}</p>}
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <Button
              className={classes.saveButton}
              type="submit"
              variant="contained"
            >
              Cambiar contraseña
            </Button>
          </CardActions>
        </form>
      </Card>
    </Page>
  );
};

ChangePass.propTypes = {
  className: PropTypes.string,
  profile: PropTypes.object.isRequired
}

export default ChangePass
