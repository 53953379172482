import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  CircularProgress,
  colors,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  lighten,
  MenuItem,
  OutlinedInput,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Typography,
} from '@material-ui/core';

import ClearIcon from '@material-ui/icons/Clear';
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import { toast } from 'react-toastify';
import GeneralService from '../../../../services/GeneralService'
import SweetAlert from 'sweetalert2-react';
import emptyImg from '../../../../assets/image/empty-trans.png'


const toastSuccess = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true
}

const toastError = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true
}

const useStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const headCells = [
  { id: 'id', numeric: true, disablePadding: true, label: 'ID' },
  { id: 'date', numeric: true, disablePadding: false, label: 'Fecha' },
  { id: 'detail', numeric: false, disablePadding: false, label: 'Detalle' },
  { id: 'pocket', numeric: false, disablePadding: false, label: 'Bolsillo' },
  { id: 'amount', numeric: true, disablePadding: false, label: 'Cantidad' },
  { id: 'transType', numeric: false, disablePadding: false, label: 'Tipo' },
  { id: 'state', numeric: false, disablePadding: false, label: 'Estado' },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
}

const Transactions = props => {
  const { className, ...rest } = props;

  const calculofecha = (restar) => {
    let tempDate = new Date()
    if (restar == true) {
      tempDate.setDate(tempDate.getDate() - 7)
    }
    return tempDate
  }

  const dateFormat = (tempDate) => {
    return (
      tempDate.getFullYear() +
      '-' +
      (tempDate.getMonth() < 9 ? '0' : '') +
      (tempDate.getMonth() + 1).toString() +
      '-' +
      tempDate.getDate()
    )
  }

  const classes = useStyles();
  const [ isLoading, setLoading ] = useState( false );
  const [ userID, setUserId ] = useState();
  const [ history, setHistory ] = useState([]);
  const [ username, setUsername ] = useState("");
  const [ name, setName ] = useState("");
  const [ startDate, setStartDate ] = useState(dateFormat(calculofecha(true)));
  const [ endDate, setEndDate ] = useState(dateFormat(calculofecha(false)));
  const [ pocket, setPocket ] = useState(0);
  const [ userSet, userSetted ] = useState( false )
  const [ searchingUser, setSearchUser ] = useState( false );
  const [ pocketList, setpocketList ] = useState([{id:1,name:"Movilpesos"},{id:8,name:"salud"}]);
  const [ page, setPage ] = useState(0);
  const [ rowsPerPage, setRowsPerPage ] = useState(5);
  const [ order, setOrder ] = useState('asc');
  const [ orderBy, setOrderBy ] = useState('id');

  const searchUser = () => {
    setSearchUser( true )
    if(username !== ""){
      const generalService = new GeneralService();
      generalService.getUserById(username).then((response) => {
        setSearchUser( false )
        if (response.description === 'user not found') {
          userSetted( false )
          toast.error("Usuario no existe !", toastError);
          setSearchUser(false)
        } else {
          userSetted( true )
          toast.success("Usuario existe. !", toastSuccess); 
          setUserId(response.data.id)
          setName(" del usuario: "+response.data.name+ " " +response.data.lastName)
          searchHistory(response.data.id);
          searchUserPockets(response.data.id);
          setSearchUser(false)
        }
      })
    }else{
      toast.error("Ingrese el identificador del usuario!", toastError);
    }
  }

  const searchUserPockets = (id) => {
    const generalService = new GeneralService();
    generalService.getUserpockets(id).then((response) => {
      if (response.statusCode === 200) {
        let listaRecibida = response.data
        let lista = []
        for (let pocket of listaRecibida) {
          if (
            (pocket.pocket.pocketType.name == 'bonodirigido' ||
              pocket.pocket.pocketType.name == 'movilpesos') &&
            pocket.active
          ) {
            let item = {
              id: pocket.pocket.id,
              name: pocket.customName != null ? pocket.customName : pocket.pocket.description,
            }
            lista.push(item)
          }
        }
        setpocketList(lista)
      } else {
      }
    })
  }

  const searchHistory = (id) => {
    setLoading( true )
    setPage(0)
    if(startDate !== "" && endDate !== ""){
      const generalService = new GeneralService();
      generalService.getUserHistory(id, startDate, endDate, pocket).then((response) => {
        // console.log(response)
        if(response.statusCode==200){
          let listaRecibida = response.data
          let lista = []
          for (let record of listaRecibida) {
            // console.log(record)
            const date = new Date(record.dateTransaction)
            let fee = 0
            if(record.transactionType.ingress){
              fee = record.fee
            }else{
              fee = record.fee * -1
            }
            let item = {
              id: record.id,
              date: dateFormat(date),
              detail: record.detail,
              amount: fee,
              transType: record.transactionType.id,
              transTypeLabel: record.transactionType.description,
              isIngress: record.transactionType.ingress,
              state: record.transactionStatus.description,
              pkt: record.pocket.id,
              pktLabel: record.pocket.description,
            }
            lista.push(item)
          }
          // console.log(lista)
          setLoading(false)
          setHistory(lista)
        }
      })
    }else{
      setLoading(false)
      toast.error("Fechas invalida!", toastError); 
    }
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, history.length - page * rowsPerPage);

  const buttonUser =  searchingUser ? <Loader type="ThreeDots" color="#25d366" height={20} width={20}/> : <i class="far fa-user"> Buscar Usuario </i>
  const buttonHistory =  isLoading ? <Loader type="ThreeDots" color="#25d366" height={20} width={20}/> : <i class="far fa-user"> Buscar Historial </i>

  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <CardHeader title={"Transacciones "+ name} action={
        <IconButton aria-label="settings" onClick={() => props.handleClick()}>
          <ClearIcon />
        </IconButton>
        }/>
        <Divider />
        <CardContent>
          <Grid container spacing={4}>
            <Grid item md={10} xs={12}>
              <TextField
                fullWidth
                helperText="Introduce el DNI del usuario a cobrar."
                label="DNI del usuario"
                placeholder='1002333655 DNI'
                name='dni'
                required
                variant="outlined"
                onChange={event => setUsername(event.target.value)}
              />
            </Grid>
            <Grid item md={2} xs={12} >
              <Button className={classes.saveButton} type="button" variant="contained" onClick={() => searchUser()} > { buttonUser } </Button>
            </Grid>
          </Grid>
          {userSet && (
            <Grid container spacing={4} style={{ marginBottom: "1rem"}}>
              <Grid item md={3} xs={6} >
                <OutlinedInput
                  id="standard-adornment-weight"
                  type='date'
                  value={(startDate)}
                  onChange={e => setStartDate(e.target.value)}
                  endAdornment={<InputAdornment position="end">Fecha Inicio</InputAdornment>}
                  aria-describedby="standard-weight-helper-text"
                  inputProps={{
                    'aria-label': 'weight',
                  }}
                  style={{width: '100%'}}
                />
              </Grid>
              <Grid item md={3} xs={6} >
                <OutlinedInput
                  id="standard-adornment-weight"
                  type='date'
                  value={(endDate)}
                  onChange={e => setEndDate(e.target.value)}
                  endAdornment={<InputAdornment position="end">Fecha Fin</InputAdornment>}
                  aria-describedby="standard-weight-helper-text"
                  inputProps={{
                    'aria-label': 'weight',
                  }}
                  style={{width: '100%'}}
                />
              </Grid>
              <Grid item md={3} xs={6} >
                <FormControl style={{width: '100%'}}>
                  <InputLabel id="select-label">Bolsillo</InputLabel>
                  <Select
                    labelId="select-label"
                    id="select"
                    value={pocket}
                    onChange={e => setPocket(e.target.value)}
                  >
                    <MenuItem value={0}>Seleccionar</MenuItem>
                    {pocketList.map( (item) => (
                      <MenuItem value={item.id} key={item.id}>{item.name}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item md={3} xs={6} >
                <Button className={classes.saveButton} type="button" variant="contained" onClick={() => searchHistory(userID)} > { buttonHistory } </Button>
              </Grid>
            </Grid>
          )}
          {isLoading &&(
            <Box component="span" style={{ display:'flex', justifyContent:'center', alignContent:'center' }}>
              <CircularProgress/>
            </Box>
          )}
          {history.length>0 && !isLoading && userSet  && (
            <Paper className={classes.paper}>
              <TableContainer>
                <Table
                  className={classes.table}
                  aria-labelledby="tableTitle"
                  size={'medium'}
                  aria-label="enhanced table"
                >
                  <EnhancedTableHead
                    classes={classes}
                    order={order}
                    orderBy={orderBy}
                    onRequestSort={handleRequestSort}
                  />
                  <TableBody>
                    {stableSort(history, getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        const labelId = `enhanced-table-checkbox-${index}`;
      
                        return (
                          <TableRow
                            hover
                            role="checkbox"
                            tabIndex={-1}
                            key={row.id}
                          >
                            <TableCell align="center" id={labelId}>
                              {row.id}
                            </TableCell>
                            <TableCell align="center">{row.date}</TableCell>
                            <TableCell align="center">{row.detail}</TableCell>
                            <TableCell align="center">{row.pktLabel}</TableCell>
                            <TableCell align="center">{ row.amount }</TableCell>
                            <TableCell align="center">{row.transTypeLabel}</TableCell>
                            <TableCell align="center">{row.state}</TableCell>
                          </TableRow>
                        );
                      })}
                    {emptyRows > 0 && (
                      <TableRow style={{ height: 53 * emptyRows }}>
                        <TableCell colSpan={6} />
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={history.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Paper>
          )}
          {history.length==0 && !isLoading && userSet  && (
            <img src={emptyImg} alt="empty-trans" style={{ width: '100%' }}/>
          )}
        </CardContent>
        <Divider />
        <CardActions>
      </CardActions>
    </Card>
  );
};

export default Transactions;
