import React, { useEffect, useMemo, useState } from "react";
import ClearIcon from "@material-ui/icons/Clear";
import {
    Container,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
} from "@material-ui/core";
import "./../styles/withdrawalAndPayments.css";
import moment from "moment";
import { utils, write } from "xlsx";
import { get_withdrawal_and_payments } from "../../../../services/WithdrawalAndPayments";

const WithdrawalAndPaymentsTableList = (props) => {
    const { data } = props;
    function createData(
        idtransaccion,
        fecha,
        idreferencia,
        apellido,
        nombre,
        ramacomercio,
        tarifa
    ) {
        return {
            idtransaccion,
            fecha,
            idreferencia,
            apellido,
            nombre,
            ramacomercio,
            tarifa,
        };
    }

    const rows = useMemo(() => {
        return (
            data?.map((register) => {
                return createData(
                    register.idtransaccion.toString(),
                    moment(register.fecha)
                        .locale("es")
                        .format("DD/MM/YYYY, h:mm:ss a"),
                    register.idreferencia.toString(),
                    register.apellido,
                    register.nombre,
                    register.ramacomercio,
                    register.tarifa.toString()
                );
            }) ?? []
        );
    }, [data]);
    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <b>ID</b>
                        </TableCell>
                        <TableCell align="right">
                            <b>fecha</b>
                        </TableCell>
                        <TableCell align="right">
                            <b>REFERENCIA</b>
                        </TableCell>
                        <TableCell align="right">
                            <b>APELLIDO</b>
                        </TableCell>
                        <TableCell align="right">
                            <b>NOMBRE</b>
                        </TableCell>
                        <TableCell align="right">
                            <b>RAMA COMERCIO</b>
                        </TableCell>
                        <TableCell align="right">
                            <b>TARIFA</b>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row) => (
                        <TableRow
                            key={row.name}
                            sx={{
                                "&:last-child td, &:last-child th": {
                                    border: 0,
                                },
                            }}
                        >
                            <TableCell component="th" scope="row">
                                {row.idtransaccion}
                            </TableCell>
                            <TableCell align="right">{row.fecha}</TableCell>
                            <TableCell align="right">
                                {row.idreferencia}
                            </TableCell>
                            <TableCell align="right">{row.apellido}</TableCell>
                            <TableCell align="right">{row.nombre}</TableCell>
                            <TableCell align="right">
                                {row.ramacomercio}
                            </TableCell>
                            <TableCell align="right">
                                <b>$ {row.tarifa}</b>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const WithdrawalAndPayments = (props) => {
    const [data, setData] = useState([]);
    const [searchValue, setSearchValue] = useState("");

    const onChangeSearchValue = (event) => {
        event.preventDefault();
        setSearchValue(event.target.value);
    };

    const dataFiltered = useMemo(() => {
        return data.filter((register) => {
            const searchValueLowerCase = searchValue.toLowerCase().trim();
            const hasName = register.nombre
                .toLowerCase()
                .includes(searchValueLowerCase);
            const hasLastName = register.apellido
                ?.toLowerCase()
                .includes(searchValueLowerCase);
            const hasIdReferencia = register.idreferencia
                ?.toString()
                .toLowerCase()
                .includes(searchValueLowerCase);
            const hasIdTransaccion = register.idtransaccion
                ?.toString()
                .toLowerCase()
                .includes(searchValueLowerCase);
            const hasRamaComercio = register.ramacomercio
                ?.toLowerCase()
                .includes(searchValueLowerCase);
            const hasTarifa = register.tarifa
                ?.toString()
                .toLowerCase()
                .includes(searchValueLowerCase);
            const hasDate = register.fecha?.toString().includes(searchValue);
            if (
                [
                    hasName,
                    hasLastName,
                    hasIdReferencia,
                    hasIdTransaccion,
                    hasRamaComercio,
                    hasTarifa,
                    hasDate,
                ].some((bool) => bool === true)
            ) {
                return register;
            } else return null;
        });
    }, [searchValue, data]);

    useEffect(() => {
        get_withdrawal_and_payments()
            .then((response) => {
                setData(response.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const createArrayExcel = () => {
        const workbook = utils.book_new();
        const spreadSheet = utils.json_to_sheet(data);
        utils.book_append_sheet(workbook, spreadSheet, "retiros y pagos");
        const excelFile = write(workbook, { bookType: "xlsx", type: "binary" });

        function s2ab(s) {
            const buf = new ArrayBuffer(s.length);
            const view = new Uint8Array(buf);
            for (let i = 0; i < s.length; i++) {
                view[i] = s.charCodeAt(i) & 0xff;
            }
            return buf;
        }

        const blob = new Blob([s2ab(excelFile)], {
            type: "application/octet-stream",
        });

        const url = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.download = "retiros_y_pagos_" + moment().format("l") + ".xlsx";
        link.click();

        URL.revokeObjectURL(url);
    };

    // let column = 4;
    return (
        <div>
            <div className="WAPContainerActions">
                <IconButton
                    aria-label="settings"
                    onClick={() => props.handleClick()}
                >
                    <ClearIcon />
                </IconButton>
                <div className="WAPContainerActionsRight">
                    <TextField
                        value={searchValue}
                        onChange={onChangeSearchValue}
                        placeholder="Buscar"
                    />
                    <button
                        disabled={!data.length}
                        className="WAPContainerActionsRightButton"
                        onClick={createArrayExcel}
                    >
                        Descargar Excel
                    </button>
                </div>
            </div>
            <Container maxWidth="lg">
                <WithdrawalAndPaymentsTableList data={dataFiltered} />
            </Container>
        </div>
    );
};

export default WithdrawalAndPayments;
