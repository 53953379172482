import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import Page from '../../../helpers/Page'
import Header from '../../../helpers/Header'
import Autocomplete from '@material-ui/lab/Autocomplete';
import LocationOnIcon from '@material-ui/icons/LocationOn';
import {
  useHistory
} from "react-router-dom";
import {
  Button,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  FormControlLabel,
  Grid,
  Divider,
  TextField,
  Typography,
  colors,
  Switch,
} from '@material-ui/core';

import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify';
import { geocodeByPlaceId, getLatLng } from 'react-google-places-autocomplete';
import SucursalService from '../../../services/SucursalService'
import GeneralService from '../../../services/GeneralService'

import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';

const useStyles = makeStyles(theme => ({
  root: {},
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  },
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  TopMargin: {
    marginTop: theme.spacing(3)
  },
}));

const autocompleteService = { current: null };

const CreateSucursal = props => {
  const { className, ...rest } = props;
  const classes = useStyles();
  const userData = props.userData ? props.userData : {}
  const { register, handleSubmit, formState: { errors }, reset } = useForm()
  const [isLoading, setLoading] = useState(false);
  const [cities, setCities] = useState([]);
  const [currentLatLang, setLangLat] = useState({latitude: 0, longitude: 0});
  const [direction, setDirection] = useState('');
  const [autoAddress, setAutoAddress] = useState(true);
  
  const navigate = useHistory();

  // Google Maps Hooks
  const [inputValue, setInputValue] = React.useState('');
  const [options, setOptions] = React.useState([]);


  const fetch = React.useMemo(
    () =>
      throttle((input, callback) => {
        autocompleteService.current.getPlacePredictions(input, callback);
      }, 200),
    [],
  );
  
  useEffect(()=>{
    let generalService = new GeneralService()
    generalService.getCities(userData).then((response) => {
      setCities(response.data)
    })
  }, [])

  // De forma similar a componentDidMount y componentDidUpdate
  useEffect(() => {
    // Actualiza el título del documento usando la API del navegador
    let active = true;

    if (!autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions([]);
      return undefined;
    }

    fetch({ input: inputValue }, results => {
      if (active) {
        setOptions(results || []);
      }
    });

    return () => {
      active = false;
    };
  }, [inputValue, fetch]);

  const handleChange = event => {
    setInputValue(event.target.value);
  };

  const onSubmit = data => { 
    const formData = {
      "position": {
        "lon": autoAddress ? currentLatLang.longitude : data.longitude,
        "lat": autoAddress ? currentLatLang.latitude : data.latitude,
        "SRID": 4326
      },
      "city": {
        "id": data.city,
        "countryId": 472
      },  
      "name": data.name,
      "address": autoAddress ? direction : data.direction,
      "email": data.email
     }
    let sucursalService = new SucursalService();
    let UserData = userData;
    // console.log(formData)
    
    sucursalService.CreateSucursalService(formData, UserData.commerce.id, UserData).then((response) => {
      if(response.description === "Success"){
          setLoading(!isLoading)
          toast.success("Sucursal Creada !");
          reset()
          navigate.push("/admin/sucursal/index")
        } else {
          reset()
          toast.error(response.description);  
        }  
     })
  }

  const getLocalization = place => {
    if(place != null){
      geocodeByPlaceId(place.place_id)
        .then(results => {
          setDirection(results[0].formatted_address)
          getLatLng(results[0])
            .then(({ lat, lng }) => {
              setLangLat({ latitude: lat, longitude: lng })
            })
        })
        .catch(error => console.error(error));
    }else{
      setDirection('')
      setLangLat({ latitude: 0, longitude: 0 })
    }
  }

  return (
    <Page
      className={classes.root}
      title="Creacion de sucursales - Movilplata"
    >
      <Header mainTitle='Movilplata' subTitle='Creacion de sucursales.'/>
      <Card
        {...rest}
        className={clsx(classes.root, className, classes.TopMargin)}
      >
        <form onSubmit={handleSubmit(onSubmit)}>
          <CardHeader title="Completa la informacion, para crear la sucursal." />
          <Divider />
          <CardContent>
            <Grid
              container
              spacing={4}
            >
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  helperText="Por favor indica el nombre de la sucursal"
                  label="Nombre de la sucursal"
                  name="name"
                  // onChange={handleChange}
                  required
                  variant="outlined"
                  {...register("name", { required: true })}/>
                  {errors.name && <p className="invalid-feedback d-block">Nombre invalido</p>}
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Direccion email"
                  helperText="En caso de ser parte de eticos, debe estar registrada en la BD correspondiente"
                  name="email"
                  // onChange={handleChange}
                  required
                  variant="outlined"
                  {...register("email", { required: true })}/>
                  {errors.email && <p className="invalid-feedback d-block">Email invalido.</p>}
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                {autoAddress ? (<Autocomplete
                  id="google-map-demo"
                  getOptionLabel={option => (typeof option === 'string' ? option : option.description)}
                  filterOptions={x => x}
                  options={options}
                  autoComplete
                  includeInputInList
                  freeSolo
                  disableOpenOnFocus
                  onChange={(event, value) => getLocalization(value)}
                  renderInput={params => (
                    <TextField
                      {...params}
                      label="Add a location"
                      variant="outlined"
                      fullWidth
                      name='place'
                      onChange={handleChange}
                      required
                      // {...register("place", { required: true })}
                    />
                  )}
                  renderOption={option => {
                    const matches = option.structured_formatting.main_text_matched_substrings;
                    const parts = parse(
                      option.structured_formatting.main_text,
                      matches.map(match => [match.offset, match.offset + match.length]),
                    );
                    return (
                      <Grid container alignItems="center">
                        <Grid item>
                          <LocationOnIcon className={classes.icon} />
                        </Grid>
                        <Grid item xs>
                          {parts.map((part, index) => (
                            <span key={index} style={{ fontWeight: part.highlight ? 700 : 400 }}>
                              {part.text}
                            </span>
                          ))}

                          <Typography variant="body2" color="textSecondary">
                            {option.structured_formatting.secondary_text}
                          </Typography>
                        </Grid>
                      </Grid>
                    );
                  }}
                />) :
                (
                  <Box>
                    <TextField
                    fullWidth
                    helperText="Por favor indica la dirección de la sucursal"
                    placeholder='Dirección de la sucursal'
                    name="direction"
                    required
                    variant="outlined"
                    {...register("direction", { required: true })}/>
                    {errors.direction && <p className="invalid-feedback d-block">Dirección invalida</p>}

                    <Typography variant="body1" color="textSecondary">
                      Coodenadas
                    </Typography>
                    
                    <TextField
                    helperText="latitud"
                    placeholder='latitude de la sucursal'
                    name="latitude"
                    required
                    variant="outlined"
                    {...register("latitude", { required: true })}/>
                    {errors.latitude && <p className="invalid-feedback d-block">latitud invalida</p>}

                    <TextField
                    helperText="longitud"
                    placeholder='longitude de la sucursal'
                    name="longitude"
                    required
                    variant="outlined"
                    {...register("longitude", { required: true })}/>
                    {errors.longitude && <p className="invalid-feedback d-block">longitud invalida</p>}
                  </Box>
                  
                )}
                <FormControlLabel
                  control={
                    <Switch
                      checked={autoAddress}
                      onChange={(event) => setAutoAddress(event.target.checked)}
                      color="primary"
                    />
                  }
                  label="Auto complete"
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Selecciona la ciudad"
                  name="city"
                  onChange={handleChange}
                  select
                  SelectProps={{ native: true }}
                  variant="outlined"
                  {...register("city", { required: true })}>
                <option value='' key='0'></option>    
                {cities.map((item, key) => 
                  <option value={item.id} key={key}>{item.name}</option>
                )}
                </TextField>
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <CardActions>
            <Button
              className={classes.saveButton}
              type="submit"
              variant="contained"
            >
              Save Changes
            </Button>
          </CardActions>
        </form>
      </Card>
    </Page>
  );
};

CreateSucursal.propTypes = {
  className: PropTypes.string,
  profile: PropTypes.object.isRequired
}

export default CreateSucursal
