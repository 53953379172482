import React from "react";

import Grid from "@material-ui/core/Grid";

import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import Box from "@material-ui/core/Container";

import CardPos from "./helpers/CardPos";

export default function InteractionPanel(props) {
    // const userData = props.userData ? props.userData : null;
    let column = 4;

    return (
        <Box component="div" m={1}>
            <CssBaseline />
            <Container maxWidth="lg">
                <Grid container spacing={3}>
                    <Grid item xs={12} md={column}>
                        <CardPos
                            title="Editar usuario"
                            subtitle="Editar la información use un usuario"
                            movilAction={() => props.handleClick("edit")}
                        />
                    </Grid>
                    <Grid item xs={12} md={column}>
                        <CardPos
                            title="Movimientos"
                            subtitle="Ver movimientos de user"
                            movilAction={() =>
                                props.handleClick("transactions")
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={column}>
                        <CardPos
                            title="Cambiar contraseña"
                            subtitle="Cambiar la contraseña de un usuario"
                            movilAction={() => props.handleClick("editPass")}
                        />
                    </Grid>
                    <Grid item xs={12} md={column}>
                        <CardPos
                            title="Retiros y pagos"
                            subtitle="Ver el registro de los últimos 7 días"
                            movilAction={() =>
                                props.handleClick("withdrawallAndPayments")
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={column}>
                        <CardPos
                            title="Saldos Clientes"
                            subtitle="Ver el registro de saldos"
                            movilAction={() =>
                                props.handleClick("balancesClients")
                            }
                        />
                    </Grid>
                    <Grid item xs={12} md={column}>
                        <CardPos
                            title="Creación de usuarios masivos"
                            subtitle="Crear usuarios masivos a partir de un archivo"
                            movilAction={() =>
                                props.handleClick("insertMassiveUsers")
                            }
                        />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
}
