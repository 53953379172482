import React from 'react'
import icon1 from '../assets/IconLand4.png'
import icon2 from '../assets/IconLand5.png'
import icon3 from '../assets/IconLand6.png'
import imageCell from '../assets/CellHomeApp.png'
import googleImage from '../assets/google.png'
import appleImage from '../assets/apple.png'
import appImage from '../assets/IconApp.png'
import {
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#FFFFFF',
    paddingTop: '5rem',
    // paddingBottom: '6rem',
  },
  content:{
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
    padding: '1.75rem',
  },
  contentM:{
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  subContent:{
    flexDirection: 'column',
    paddingLeft: '1rem',
  },
  contentPhone:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  topic:{
    borderRadius: '17px',
    backgroundColor: '#51E367',
    boxShadow: '0px 15px 6px #00000029',
    padding: '0.5rem 1.5rem',
    color: '#0A5359',
  },
  title:{
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: '#0A5359',
  },
  titleM:{
    fontSize: '1rem',
    fontWeight: 'bold',
    color: '#0A5359',
  },
  text:{
    color: '#0A5359',
    fontSize: '1rem',
    textAlign: 'left',
  },
  textM:{
    color: '#0A5359',
    fontSize: '0.8rem',
    textAlign: 'left',
  },
  moreLink:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
  },
  titleApp:{
    display: 'none',
    fontSize: '1.5rem',
    color: '#000',
    width: '90%',
  },
  textApp:{
    display: 'none',
    fontSize: '1rem',
    color: 'blue',
    width: '90%',
  },
  downloadIcons:{
    width: '100px',
    height: '100px',
  },
  downloadIconsM:{
    width: '50px',
    height: '50px',
  },
  columnContent: {
    width: '50%',
    padding: '15px',
  },
  sectionMovil: {
    marginTop: '30px',
  },
}));

export default function Download() {
  const classes = useStyles();
  return (
    <section className={classes.root} id='download'>
      <Grid container>
        <Grid item md={6} xs={6} className={classes.contentPhone + ' downloadImage'}>
          <img src={imageCell}  width='300' alt='movilplata-logo' className={'hideWeb'}/>
          <img src={appImage}  width='80%' alt='movilplata-logo' className={'hideMovil showmovil'}/>
        </Grid>
        <Grid item md={6} xs={12} className={'hideWeb'}>
          <div className={classes.content}>
            <img src={icon1} className={classes.downloadIcons} alt='movilplata-descarga'/>
            <div className={classes.subContent}>
              <h1 className={classes.title}>DESCARGA</h1>
              <p className={classes.text}>
                Desde tu teléfono inteligente en Google Play o App Store puedes encontrar nuestro logo MOVILPLATA y descargarla.
              </p>
            </div>
          </div>
          <div className={classes.content}>
            <img src={icon2} className={classes.downloadIcons} alt='movilplata-registro'/>
            <div className={classes.subContent}>
              <h1 className={classes.title}>REGÍSTRATE</h1>
              <p className={classes.text}>
                Ya descargada tu App Movilplata, debajo de el botón ingresar. Selecciona ¡Regístrate Ahora!
              </p>
            </div>
          </div>
          <div className={classes.content}>
            <img src={icon3} className={classes.downloadIcons} alt='movilplata-perfil'/>
            <div className={classes.subContent}>
              <h1 className={classes.title}>Perfil</h1>
              <p className={classes.text}>
                Al entrar a tu cuenta con tú numero de documento y contraseña registrada. Puedes evidenciar el saldo de tus Movilbonos con los cuales podrás hacer compras en comercios aliados y de tus Movilpesos con los que puedes comprar o retirar desde puntos autorizados.
              </p>
            </div>
          </div>
        </Grid>
        <Grid item md={6} xs={12} className={classes.sectionMovil + ' hideMovil showmovil'}>
          <div className={classes.columnContent}>
            <div className={classes.contentM}>
              <img src={icon1} className={classes.downloadIconsM} alt='movilplata-descarga'/>
              <div className={classes.subContent}>
                <h1 className={classes.titleM}>DESCARGA</h1>
              </div>
            </div>
            <p className={classes.textM}>
              Desde tu teléfono inteligente en Google Play o App Store puedes encontrar nuestro logo MOVILPLATA y descargarla.
            </p>
            <div className={classes.contentM}>
              <img src={icon2} className={classes.downloadIconsM} alt='movilplata-registro'/>
              <div className={classes.subContent}>
                <h1 className={classes.titleM}>REGÍSTRATE</h1>
              </div>
            </div>
            <p className={classes.textM}>
              Ya descargada tu App Movilplata, debajo de el botón ingresar. Selecciona ¡Regístrate Ahora!
            </p>
          </div>
          <div className={classes.columnContent}>
            <div className={classes.contentM}>
              <img src={icon3} className={classes.downloadIconsM} alt='movilplata-perfil'/>
              <div className={classes.subContent}>
                <h1 className={classes.titleM}>Perfil</h1>
              </div>
            </div>
            <p className={classes.textM}>
              Al entrar a tu cuenta con tú numero de documento y contraseña registrada. Puedes evidenciar el saldo de tus Movilbonos con los cuales podrás hacer compras en comercios aliados y de tus Movilpesos con los que puedes comprar o retirar desde puntos autorizados.
            </p>
          </div>
        </Grid>
        <Grid item md={12} xs={6} className={classes.moreLink + ' storeLinks'}>
          <a href="https://play.google.com/store/apps/details?id=com.cps.movilplata&hl=es_CO&gl=US">
            <img src={googleImage} width='80%' alt='movilplata-logo'/>
          </a>
          <a href="https://apps.apple.com/co/app/movilplata-app/id1488266711">
            <img src={appleImage}  width='80%' alt='movilplata-logo'/>
          </a>
          <h1 className={classes.textApp + ' showmovil'}>Movilplata sas</h1>
          <h1 className={classes.titleApp + ' showmovil'}>Movilplata App</h1>
        </Grid>
      </Grid>
    </section>
  )
}
