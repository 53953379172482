import React, { useState } from 'react'
import Loader from 'react-loader-spinner'
import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify';
import logoImg from './assets/logo.png'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import RegisterService from '../../../services/AuthService'
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  content:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    textAlign: 'center',
    flexDirection: 'column',
  },
  form: {
    width: '80%',
    marginTop: '1rem',
  },
  title:{
    fontWeight: 'bold',
    color: '#0A5359',
    borderBottom: '3px solid #0A5359',
  },
  contInput:{
    padding: '5px',
    color: '#0A5359',
  },
  botonMain:{
    backgroundColor: 'transparent',
    borderRadius: '10px',
    border: '3px solid white',
    width: '100%',
    padding: '10px',
    color: '#0A5359',
    fontWeight: 'Bold',
    fontSize: '1rem',
  },
  botonSecond:{
    backgroundColor: '#0A5359',
    borderRadius: '10px',
    width: '100%',
    padding: '10px',
    color: 'white',
    fontWeight: 'Bold',
    fontSize: '1rem',
    border: 'unset',
  }
}));

const toastSuccess = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true
}

const toastError = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true
}

const RegisterForm = (props) => {
  const classes = useStyles();
  const { register, handleSubmit, watch, formState: { errors } } = useForm()

  const onSubmitRegister = data => { 
    let registerService = new RegisterService();
    props.handleLoading(true)
    registerService.register(data).then((response) => {
      // console.log('Register Form', response)
      if (response.description === "Success") {
        toast.success("Registro Completado !", toastSuccess);
        props.handleLoading(false)
        props.modifyState()
      } else {
        console.log('Error en Login', response)
        toast.error(response.description, toastError);
        props.handleLoading(false)
      }
    })
  }

  const buttonText =  props.isLoading ? <Loader type="ThreeDots" color="#25d366" height={20} width={20}/> : 'Registrarse'

  return (
    <div className={classes.content}>
      <form onSubmit={handleSubmit(onSubmitRegister)} name='registerForm' className={classes.form}>
      <Grid container>
        <Grid item md={6} xs={12} className={classes.contInput}>
          <label className="auth-label">Nombre*</label>
          <input  type="text" 
            className={`form-control ${ errors.name && 'is-invalid' }`} 
            placeholder=""
            name='name'
            {...register("name", { required: true })}/>
          {errors.name && <p className="invalid-feedback d-block">Nombre requerido.</p>}
        </Grid>
        <Grid item md={6} xs={12} className={classes.contInput}>
          <label className="auth-label">Apellido*</label>
          <input  type="text" 
            className={`form-control ${ errors.lastname && 'is-invalid' }`} 
            placeholder=""
            name='lastname'
            {...register("lastname", { required: true })}/>
          {errors.lastname && <p className="invalid-feedback d-block">Apellido requerido.</p>}
        </Grid>
        <Grid item md={6} xs={12} className={classes.contInput}>
          <label className="auth-label">Numero de Identificacion (CC)*</label>
          <input  type="text" 
            className={`form-control ${ errors.username && 'is-invalid' }`} 
            placeholder=""
            name='username'
            {...register("username", { required: true, pattern: /^[0-9]*$/ })}/>
          {errors.username && <p className="invalid-feedback d-block">Usuario Invalido</p>}
        </Grid>
        <Grid item md={6} xs={12} className={classes.contInput}>
          <label className="auth-label">Telefono*</label>
          <input  type="text" 
            className={`form-control ${ errors.phone && 'is-invalid' }`}
            name='phone'
            placeholder=""
            {...register("phone", { required: true, minLength: 8 })}/>
          {errors.phone && <p className="invalid-feedback d-block">Telefono requerido o invalido</p>}
        </Grid>
        <Grid item md={12} xs={12} className={classes.contInput}>
          <label className="auth-label">Email*</label>
          <input  type="email" 
            className={`form-control ${ errors.email && 'is-invalid' }`} 
            placeholder=""
            name='email'
            {...register("email", { required: true })}/>
          {errors.email && <p className="invalid-feedback d-block">This email is invalid</p>}
        </Grid>
        <Grid item md={6} xs={12} className={classes.contInput}>
          <label className="auth-label">Contraseña</label>
          <input  type="password" 
            className={`form-control ${ errors.password && 'is-invalid' }`} 
            placeholder="" 
            name='password'
            {...register("password", { required: true, minLength: 8 })}/>
          {errors.password && <p className="invalid-feedback d-block">Contraseña invalida</p>}
        </Grid>
        <Grid item md={6} xs={12} className={classes.contInput}>
          <label className="auth-label">Confirmación de contraseña</label>
          <input  type="password" 
            className={`form-control ${ errors.password_confirm && 'is-invalid' }`}
            name='password_confirm'
            placeholder=""
            {...register("password_confirm", { required: true, validate: (value) => value === watch('password')})}/>
          {errors.password_confirm && <p className="invalid-feedback d-block">Confirmación de contraseña erronea</p>}
        </Grid>
        
        <Grid item md={12} xs={12}>
          <div  className="text-center my-2">
            <button type="submit" className={classes.botonMain}>{ buttonText }</button>
          </div>
          <div  className="form-group my-2">
            <button  className={classes.botonSecond} onClick={props.modifyState}>
              Volver al login
            </button>
          </div>
          <p  className="small mt-3" style={{color: '#0A5359'}}>Registrandote, estas indicando que aceptas nuestros <a href="/terms"  className="ps-hero__content__link text-white">Terminos de uso</a> y <a href="/privacy" className='text-white'>la politica de privacidad</a>.</p>
        </Grid>
      </Grid>
      </form>
    </div>
  )
}

export default RegisterForm;