import axios from "axios";
import config from "../config";
import { getMpTkn } from "../helpers/getMpTkn";

export const get_balances_clients = async () => {
    const headers = {
        "Content-Type": "application/json",
        "MpTkn": getMpTkn(),
    };
    const result = await axios
        .get(
            `${config.api.production.baseHost}/api/secure/payment/checkCurrentbalance`,
            { headers }
        )
        .then((response) => {
            if (response.status === 200) {
                return { data: response.data.data, status: 200 };
            } else {
                return { transactions: response.data, status: 500 };
            }
        })
        .catch((err) => {
            return { transactions: [], status: 500, err };
        });

    return result;
};
