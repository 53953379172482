import axios from "axios";
import Config from "./../config/index";
import { getMpTkn } from "../helpers/getMpTkn";

class GeneralService {
    getCities = async () => {
        const header = `Bearer ${localStorage.getItem("token")}`;
        let res = await axios.get(
            `${Config.api.production.baseHost}/api/cities/country/472`,
            {
                withCredentials: true,
                contentType: "application/json",
                headers: { Authorization: header, "MpTkn": getMpTkn() },
            }
        );
        return res.data;
    };
    getUserById = async (username) => {
        const header = `Bearer ${localStorage.getItem("token")}`;
        let res = await axios.get(
            `${Config.api.production.baseHost}/api/secure/users?username=${username}`,
            {
                withCredentials: true,
                contentType: "application/json",
                headers: { Authorization: header, "MpTkn": getMpTkn() },
            }
        );
        return res.data;
    };
    getUserpockets = async (userId) => {
        const header = `Bearer ${localStorage.getItem("token")}`;
        let res = await axios.get(
            `${Config.api.production.baseHost}/api/secure/pocket/all/user/${userId}`,
            {
                withCredentials: true,
                contentType: "application/json",
                headers: { Authorization: header, "MpTkn": getMpTkn() },
            }
        );
        return res.data;
    };
    EditUserRequest = async (userData, IdUser) => {
        const header = `Bearer ${localStorage.getItem("token")}`;
        let res = await axios
            .put(
                `${Config.api.production.baseHost}/api/secure/user/modify`,
                {
                    id: IdUser,
                    username: userData.dni,
                    name: userData.name,
                    lastName: userData.lastname,
                    email: userData.email,
                    phone: userData.phone,
                },
                {
                    withCredentials: true,
                    contentType: "application/json",
                    headers: { Authorization: header, "MpTkn": getMpTkn() },
                }
            )
            .then(function (response) {
                // handle success
                return response;
            })
            .catch(function (error) {
                // handle error
                return {
                    data: {
                        error: error,
                        code: 500,
                        description: "Error. Contacta al administrador",
                    },
                };
            });

        return res.data;
    };
    EditPassRequest = async (userData, IdUser) => {
        const header = `Bearer ${localStorage.getItem("token")}`;
        let res = await axios
            .put(
                `${Config.api.production.baseHost}/api/secure/user/modify`,
                {
                    id: IdUser,
                    password: userData.pass,
                },
                {
                    withCredentials: true,
                    contentType: "application/json",
                    headers: { Authorization: header, "MpTkn": getMpTkn() },
                }
            )
            .then(function (response) {
                // handle success
                return response;
            })
            .catch(function (error) {
                // handle error
                return {
                    data: {
                        error: error,
                        code: 500,
                        description: "Error. Contacta al administrador",
                    },
                };
            });

        return res.data;
    };
    getUserHistory = async (userID, startDate, endDate, pocket) => {
        const header = `Bearer ${localStorage.getItem("token")}`;
        const pkt = pocket == 0 ? "" : "&pkt=" + pocket;
        let res = await axios.get(
            `${Config.api.production.baseHost}/api/secure/payment/transactions/` +
                userID +
                "?limit=1000&offset=1" + //se fijaron estos limites ya que no se requiere dinamismo
                "&sd=" +
                startDate +
                "&ed=" +
                endDate +
                pkt,
            {
                withCredentials: true,
                contentType: "application/json",
                headers: { Authorization: header, "MpTkn": getMpTkn() },
            }
        );
        return res.data;
    };
    getbanks = async (data) => {
        const header = `Bearer ${localStorage.getItem("token")}`;
        let res = await axios.get(
            `${Config.api.production.baseHost}/api/secure/payment/banks`,
            {
                withCredentials: true,
                contentType: "application/json",
                headers: { Authorization: header, "MpTkn": getMpTkn() },
            }
        );
        return res.data;
    };
}

export default GeneralService;
