export const disabledAdminPermision = () => {
    const validUsers = [
        "72430395", // Reynaldo
        "892300678",
        "25852585",
        "25852585",
        "89230067801",
        "ychogo@eticos.com",
        "mtorresb@eticos.com",
    ];
    const user =
        localStorage.getItem("tokens") != null
            ? JSON.parse(localStorage.getItem("tokens"))
            : null;
    const usernameValid = validUsers.includes(user.username);
    const emailValid = validUsers.includes(user.email);
    return !(usernameValid || emailValid);
};

export const disabledAdministrationPage = () => {
    const invalidUsers = ["892300678", "89230067804"];

    const user =
        localStorage.getItem("tokens") != null
            ? JSON.parse(localStorage.getItem("tokens"))
            : null;
    return invalidUsers.includes(user.username);
};
