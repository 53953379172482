import React, { useState, useEffect } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Divider,
  TextField,
  CircularProgress,
  OutlinedInput,
  Box,
  CssBaseline,
  InputAdornment,
  colors,
  Typography
} from '@material-ui/core';

import Page from '../../../helpers/Page'
import Header from '../../../helpers/Header'
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"
import { toast } from 'react-toastify';
import SucursalService from '../../../services/SucursalService'
import ReportService from '../../../services/ReportService'
import GeneralService from '../../../services/GeneralService'

const useStyles = makeStyles(theme => ({
  root: {},
  textField: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
    width: 200,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(3),
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    width:'50%',
  },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  }
}));
export default function DailyReport(props) {

  const classes = useStyles();
  const { className, ...rest } = props;
  const userData = typeof(props.userData) === 'string' ? JSON.parse(props.userData) : props.userData
  const currentComerce = userData ? userData.commerce : {}

  const [ type, setType ] = useState( 2 );
  const [ startDate, setStartDate ] = useState( '2019-09-13' );
  const [ endDate, setEndDate ] = useState( '2019-09-13' );
  const [ sucursal, setSucursal ] = useState( 0 )
  const [ sucursales, setSucursales] = useState( [] );
  const [ searchInput, setSearchInput ] = useState('');
  const [ userSearch, setUserSearch ] = useState(null);
  const [ isLoading, setLoading ] = useState( true );

  useEffect(() => {
    const sucursalService = new SucursalService();
    sucursalService.ListService(currentComerce, userData).then((response) => {
      if(response.data.code === 500) {
        localStorage.removeItem("tokens");
      } else {
        console.log('componentDidMountList', response.data)
        setSucursales(response.data)
        setLoading(false)
      }
    })
  }, [])

  const searchUser = () => {
    console.log(searchInput)
    if(searchInput !== "") {
      const generalService = new GeneralService();
      generalService.getUserById(searchInput).then((response) => {
        console.log(response)
        if (response.statusCode === 200) {
          toast.success("Usuario existe!");
          setUserSearch(response.data)
        } else {
          toast.error("Usuario no existe!");
        }
      })
    }else{
      toast.error("Ingresa el usuario a buscar!"); 
    }
  }

  const downloadReport = () => {
    const ReportServices = new ReportService();
    setLoading(true)

    ReportServices.GetReport(currentComerce.id, sucursal, type, startDate, endDate, (userSearch==null ? "" : userSearch.id)).then(function(response) {
      // console.log(response)
      if(response.code === 200){
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `reporte-${sucursal}-${type}-${startDate}-${endDate}-${Date.now()}.pdf`);
        document.body.appendChild(link);
        link.click();
        setUserSearch(null)
        setLoading(false)
      }else{
        toast.error("Reporte fallido!"); 
        console.log(response)
        setLoading(false)
      }
    })
  }

const buttonText =  isLoading ? <Loader type="ThreeDots" color="#25d366" height={20} width={20}/> : <i class="far fa-user"> Descargar </i>
  return (
    <Page
      className={classes.root}
      title="POS - Movilplata"
    >
      <Header mainTitle='Movilplata' subTitle='POS.'/>
      <Box component="div" m={1} style={{marginTop: '2em'}}>
        <CssBaseline />
        <Grid container spacing={3} justify='center'>
          <Grid item xs={12} md={6}>
            <Card
              {...rest}
              className={clsx(classes.root, className, classes.TopMargin)}
              style={{marginTop:'20px'}}
            >
              <CardHeader title="Completa la informacion, para descargar el reporte." />
              <Divider />
              <CardContent>
              {isLoading ? (
                  <CircularProgress/>
                ) : ( 
                  <Grid
                    container
                    spacing={4}
                    justify="center"
                  >
                    <Grid item md={12} xs={12} >
                      <OutlinedInput
                        id="standard-adornment-weight"
                        type='date'
                        onChange={e => setStartDate(e.target.value)}
                        endAdornment={<InputAdornment position="end">Fecha Inicio</InputAdornment>}
                        aria-describedby="standard-weight-helper-text"
                        inputProps={{
                          'aria-label': 'weight',
                        }}
                        style={{width: '100%'}}
                      />
                    </Grid>
                    <Grid item md={12} xs={12} >
                    <OutlinedInput
                        id="standard-adornment-weight"
                        type='date'
                        onChange={e => setEndDate(e.target.value)}
                        endAdornment={<InputAdornment position="end">Fecha Fin</InputAdornment>}
                        aria-describedby="standard-weight-helper-text"
                        inputProps={{
                          'aria-label': 'weight',
                        }}
                        style={{width: '100%'}}
                      />
                    </Grid>
                    <Grid item md={12} xs={12} >
                      <TextField fullWidth select
                        // eslint-disable-next-line react/jsx-sort-props
                        SelectProps={{ native: true }}
                        variant="outlined"
                        helperText="Selecciona la sucursal que hace el pago."
                        name="pocket"
                        //name="sucursal"
                        onChange={e => setType(e.target.value)}
                      >
                        <option value='' selected>Selecciona el tipo de reporte</option>
                        <option value='1'>Pagos</option>
                        <option value='2'>Retiros</option>
                        {/* <option value='3'>Transferencias enviadas</option>
                        <option value='4'>Transferencias recibidas</option>
                        <option value='5'>Recarga</option> */}
                      </TextField>
                    </Grid>
                    <Grid item md={12} xs={12} >
                      <TextField fullWidth name="sucursal" select
                        // eslint-disable-next-line react/jsx-sort-props
                        SelectProps={{ native: true }}
                        variant="outlined"
                        helperText="Selecciona la sucursal que hace el pago."
                        onChange={e => setSucursal(e.target.value)} 
                      >
                        <option value=''>Selecciona la sucursal</option>
                        {sucursales.map((item, key) => 
                          <option key={key} value={item.id}>{item.name}</option>
                        )}
                      </TextField>
                    </Grid>
                    <Grid item md={9} xs={12}>
                      <TextField
                        fullWidth
                        helperText="Introduce el DNI del usuario a cobrar."
                        label="DNI del usuario"
                        placeholder='1002333655 DNI'
                        variant="outlined"
                        onChange={e => setSearchInput(e.target.value)}
                      />
                    </Grid>
                    <Grid item md={3} xs={12}>
                      <Button className={classes.saveButton} type="button" variant="contained" onClick={() => searchUser()} >Buscar</Button>
                    </Grid>
                    {userSearch!==null && (
                      <Grid item md={12} xs={12}>
                        <Typography gutterBottom variant="h5" component="h2">
                          Usuario: {userSearch.name+" "+userSearch.lastName}
                        </Typography>
                      </Grid>
                    )}
                  </Grid>
                )}
              </CardContent>
              <Divider />
              <CardActions>
                <Button className={classes.saveButton} type="button" variant="contained" onClick={() => downloadReport()} > { buttonText } </Button>
              </CardActions>
            </Card>
          </Grid>
        </Grid>
      </Box>
  </Page>
  )
}
