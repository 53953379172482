import React, { Component } from 'react'
import imgLogin from './assets/imgLogin.png'
import LoginForm from './LoginForm'
import RegisterForm from './RegisterForm'
import LogoMv from './assets/Logo-Movilplata-web.png'
import {
  Grid,
} from '@material-ui/core';

export default class index extends Component {

  constructor(props) {
    super(props)
    this.state = {
      login: true,
      isLoading: false
    }
  }
  
  modifyState = () => {
    this.setState({ login: !this.state.login })
  }

  setLoading = (status) => {
    this.setState({ isLoading: status })
  }
  
  render() {
    
    return (
      <Grid container className='paddingmovil' style={{ minHeight: '100vh', backgroundImage: 'linear-gradient(to top right, #165538, #279A65, #44AF78, #9EF2B4, #A5F1CD)', padding: '30px', paddingTop: '0px'}}>
        <img src={LogoMv} className="logo sizemovil" width='250' alt='movilplata-logo' style={{position: 'absolute', left: '5%', top: '10px'}}/>
        <Grid item md={6} xs={12} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <img src={imgLogin}  width='60%' alt='movilplata-logo'/>
        </Grid>
        <Grid item md={6} xs={12} className={''}>
          {this.state.login ?  
            <LoginForm
              history={this.props.history} 
              location={this.props.location} 
              cardState={this.state.login} 
              modifyState={this.modifyState} 
              isLoading={this.state.isLoading} 
              handleLoading={(status) => { this.setLoading(status) }}
            /> :
            <RegisterForm
              history={this.props.history} 
              cardState={this.state.login} 
              modifyState={this.modifyState}
              isLoading={this.state.isLoading} 
              handleLoading={(status) => { this.setLoading(status) }}
            />
          }
        </Grid>

      </Grid>
    )
  }
}
