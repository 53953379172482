import axios from "axios";
import Config from "./../config/index";
import { getMpTkn } from "../helpers/getMpTkn";

class ReportService {
    GetReport = async (comerce, sucursal, type, startDate, endDate, userID) => {
        const header = `Bearer ${localStorage.getItem("token")}`;
        let res = axios
            .get(
                `${Config.api.production.baseHost}/api/secure/report/transactions?type=${type}&sd=${startDate}&ed=${endDate}&commerce_id=${comerce}&branch_id=${sucursal}` +
                    (userID === "" ? `` : `&user_id=${userID}`),
                {
                    withCredentials: true,
                    contentType: "application/json",
                    headers: { Authorization: header, "MpTkn": getMpTkn() },
                    responseType: "blob",
                }
            )
            .then(function (response) {
                // handle success
                // console.log(response)
                return { code: response.status, data: response.data };
            })
            .catch(function (error) {
                // handle error
                return {
                    error: error.response.data,
                    code: error.response.status,
                    description: error.response,
                };
            });
        return res;
    };
}

export default ReportService;
