import React, { useCallback, useMemo, useRef, useState } from "react";
import {
    Container,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import RemoveRedEyeIcon from "@material-ui/icons/RemoveRedEye";
import VisibilityOff from "@material-ui/icons/VisibilityOff";
import * as XLSX from "xlsx";
import { insert_massive_users } from "../../../../services/InsertMassiveUsers";

const NewUsersTableList = (props) => {
    const { data, nonInsertedUsers } = props;
    const [isVisiblePasswords, setIsVisiblePasswords] = useState(false);

    function createData(name, lastname, username, phone, email, password) {
        return {
            name,
            lastname,
            username,
            phone,
            email,
            password,
        };
    }

    const rows = useMemo(() => {
        return (
            data?.map((register) => {
                return createData(
                    register.name,
                    register.lastname,
                    register.username,
                    register.phone,
                    register.email,
                    register.password
                );
            }) ?? []
        );
    }, [data]);

    const showState = useCallback(
        (row) => {
            const registerFinded = nonInsertedUsers?.find(
                (register) =>
                    register.username === row.username ||
                    register.email === row.email ||
                    register.phone === row.phone
            );
            return {
                hasError: registerFinded ? true : false,
                message: registerFinded?.motivo,
            };
        },
        [nonInsertedUsers]
    );

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        {nonInsertedUsers?.length ? (
                            <TableCell>
                                <b>ESTADO</b>
                            </TableCell>
                        ) : null}
                        <TableCell
                            align={nonInsertedUsers?.length ? "center" : "left"}
                        >
                            <b>NOMBRE</b>
                        </TableCell>
                        <TableCell align="center">
                            <b>APELLIDO</b>
                        </TableCell>
                        <TableCell align="center">
                            <b>USUARIO / C.C</b>
                        </TableCell>
                        <TableCell align="center">
                            <b>TELÉFONO</b>
                        </TableCell>
                        <TableCell align="center">
                            <b>CORREO</b>
                        </TableCell>
                        <TableCell align="right">
                            <b>CONTRASEÑA</b>{" "}
                            <span>
                                <IconButton
                                    size="small"
                                    onClick={() =>
                                        setIsVisiblePasswords(
                                            !isVisiblePasswords
                                        )
                                    }
                                >
                                    {isVisiblePasswords ? (
                                        <VisibilityOff />
                                    ) : (
                                        <RemoveRedEyeIcon />
                                    )}
                                </IconButton>
                            </span>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, idx) => (
                        <TableRow
                            key={idx}
                            sx={{
                                "&:last-child td, &:last-child th": {
                                    border: 0,
                                },
                            }}
                        >
                            {nonInsertedUsers?.length ? (
                                <TableCell
                                    align="left"
                                    component="th"
                                    scope="row"
                                >
                                    {showState(row).hasError ? (
                                        <p
                                            style={{
                                                color: "red",
                                                marginBottom: 0,
                                            }}
                                        >
                                            {showState(row).message}
                                        </p>
                                    ) : (
                                        <p
                                            style={{
                                                color: "green",
                                                marginBottom: 0,
                                            }}
                                        >
                                            Registrado correctamente
                                        </p>
                                    )}
                                </TableCell>
                            ) : null}
                            <TableCell
                                align={
                                    nonInsertedUsers?.length ? "center" : "left"
                                }
                                component="th"
                                scope="row"
                            >
                                {row?.name}
                            </TableCell>
                            <TableCell
                                align="center"
                                component="th"
                                scope="row"
                            >
                                {row?.lastname}
                            </TableCell>
                            <TableCell align="center">
                                {row?.username}
                            </TableCell>
                            <TableCell align="center">{row?.phone}</TableCell>
                            <TableCell align="center">{row?.email}</TableCell>
                            <TableCell align="center">
                                {isVisiblePasswords
                                    ? row?.password
                                    : "************"}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const InsertMassiveUsers = (props) => {
    const [data, setData] = useState([]);
    const fileInputRef = useRef(null);
    const [loading, setLoading] = useState(false);
    const [nonInsertedUsers, setNonInsertedUsers] = useState([]);
    const [tableMessage, setTableMessage] = useState({
        message: "",
        type: "",
    });

    const handleButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleFileUpload = (event) => {
        const file = event.target.files[0];
        if (!file) {
            setNonInsertedUsers([]);
            setTableMessage({ message: "", type: "" });
            return;
        }
        const reader = new FileReader();

        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[sheetName];
            const json = XLSX.utils.sheet_to_json(worksheet, { header: 1 });

            // Convert the JSON data to the desired format
            const formattedData = json.slice(1).map((row) => ({
                name: row[0],
                lastname: row[1],
                username: row[2],
                phone: row[3],
                email: row[4],
                password: row[5],
            }));

            setData(formattedData);
        };

        reader.readAsArrayBuffer(file);
        setNonInsertedUsers([]);
        setTableMessage({ message: "", type: "" });
    };

    const onInsertMassiveUsers = async () => {
        setLoading(true);
        try {
            const response = await insert_massive_users(data);
            console.log(response.data);
            if (response.data.success) {
                setTableMessage({
                    message: response.data.message,
                    type: "success",
                });
            } else {
                setNonInsertedUsers(response.data.nonInsertedRecords);
                setTableMessage({
                    message: response.data.message,
                    type: "error",
                });
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            setTableMessage({
                message:
                    "Ha ocurrido un error inesperado. Por favor, inténtelo mas tarde.",
                type: "error",
            });
        }
    };

    return (
        <div>
            <div className="WAPContainerActions">
                <IconButton onClick={() => props.handleClick()}>
                    <ClearIcon />
                </IconButton>
                <div className="WAPContainerActionsRight">
                    <input
                        type="file"
                        accept=".xlsx, .xls"
                        onChange={handleFileUpload}
                        ref={fileInputRef}
                        style={{ display: "none" }}
                    />
                    <button
                        disabled={loading}
                        className="WAPContainerActionsRightButton"
                        onClick={handleButtonClick}
                    >
                        Cargar excel
                    </button>
                    <button
                        disabled={!data?.length || loading}
                        className="WAPContainerActionsRightButton"
                        onClick={onInsertMassiveUsers}
                    >
                        {loading ? "Cargando..." : "Realizar carga masiva"}
                    </button>
                </div>
            </div>
            <Container maxWidth="lg">
                {tableMessage.message ? (
                    <p
                        style={{
                            color:
                                tableMessage.type === "success"
                                    ? "green"
                                    : "red",
                            fontSize: "14px",
                        }}
                    >
                        {tableMessage.message}
                    </p>
                ) : null}
                <NewUsersTableList
                    data={data}
                    nonInsertedUsers={nonInsertedUsers}
                />
            </Container>
        </div>
    );
};

export default InsertMassiveUsers;
