import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Divider,
  TextField,
  CircularProgress,
  colors,
  IconButton,
  Box,
  Typography,
} from '@material-ui/core';

import ClearIcon from '@material-ui/icons/Clear';

import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

import { useForm } from 'react-hook-form'
import { toast } from 'react-toastify';
import GeneralService from '../../../../services/GeneralService'

const toastSuccess = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true
}

const toastError = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true
}

const useStyles = makeStyles(theme => ({
  root: {},
  textForm: {
    fontSize: "1rem",
  },
  saveButton: {
    color: theme.palette.white,
    backgroundColor: colors.green[600],
    '&:hover': {
      backgroundColor: colors.green[900]
    }
  }
}));

const EditForm = props => {
  const { className, ...rest } = props;

  const classes = useStyles();
  const [ isLoading, setLoading ] = useState( false );
  const { register, handleSubmit, errors, getValues, setValue } = useForm()
  const [ userID, setUserId ] = useState();
  const [ userData, setUserData ] = useState(null);
  const [ dateRegis, setUserDateRegis ] = useState("");
  const [ userSet, userSetted ] = useState( false )
  const [ searchingUser, setSearchUser ] = useState( false );


  const handleBlur = () => {
    const values = getValues()
    // console.log(values)
    setSearchUser(true)
    if(values.dni !== "") {
      const generalService = new GeneralService();
      generalService.getUserById(values.dni, props.userData).then((response) => {
        setSearchUser( false )
        if (response.description === 'user not found') {
          userSetted( false )
          toast.error("Usuario no existe !", toastError);
          setSearchUser(false) 
        } else {
          userSetted( true )
          toast.success("Usuario existe. !", toastSuccess); 
          setUserId(response.data.id)
          setUserData(response.data)
          setUserDateRegis(new Date(response.data.registrationDate)+"")
          // console.log(response.data)
          setValue("name", response.data.name)
          setValue("lastname", response.data.lastName)
          setValue("phone", response.data.phone)
          setValue("email", response.data.email)
          setSearchUser(false)
        }
      })
    }else{
      toast.error("Ingresa el usuario a buscar !", toastError); 
      setSearchUser(false)
    }
  }

  const onSubmit = async data => {
    setLoading(true)
    console.log(data)
    
    const generalService = new GeneralService()
    generalService.EditUserRequest(data, userID).then( async response => {
      if(response.description === 'Success'){
        setLoading(false)
        toast.info("Cambios efectuados!", toastSuccess);
        userSetted(false)
        setSearchUser(false)
      }else{
        setLoading(false)
        toast.error("Cambios fallido!", toastError);
        setSearchUser(false)
      }
    })
  }

  const buttonText =  searchingUser ? <Loader type="ThreeDots" color="#25d366" height={20} width={20}/> : <i class="far fa-user"> Buscar Usuario </i>
  return (
    <Card
      {...rest}
      className={clsx(classes.root, className)}
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <CardHeader title='Editar user' action={
        <IconButton aria-label="settings" onClick={() => props.handleClick()}>
          <ClearIcon />
        </IconButton>
        }/>
        <Divider />
        <CardContent>
        {isLoading ? (
            <Box component="span" style={{ display:'flex', justifyContent:'center', alignContent:'center' }}>
              <CircularProgress/>
            </Box>
          ) : (
            <Grid
            container
            spacing={4}
          >
            <Grid
              item
              md={6}
              xs={12}
            >
              <Grid 
                container
                spacing={2}
              >
                  <Grid item md={12}>
                    <TextField
                      fullWidth
                      helperText="Introduce el DOC del usuario a buscar."
                      label="DOC del usuario"
                      placeholder='CC'
                      required
                      variant="outlined"
                      {...register('dni', { required: true })}
                    />
                  </Grid>
                  {userSet && (
                    <React.Fragment>
                      <Grid item md={12} xs={12} >
                        <TextField fullWidth label="Nombre" required
                          variant="outlined"
                          helperText="Nombre"
                          {...register('name', { required: true })}
                        />
                      </Grid>
                      <Grid item md={12} xs={12} >
                        <TextField fullWidth label="Apellido"
                          required
                          variant="outlined"
                          helperText="Apellido"
                          {...register('lastname', { required: true })}
                        />
                      </Grid>
                      <Grid item md={12} xs={12} >
                        <TextField fullWidth label="Telefono"
                          required
                          variant="outlined"
                          helperText="Telefono"
                          type="number"
                          min="0"
                          max="4000000000"
                          {...register('phone', { required: true })}
                        />
                      </Grid>
                      <Grid item md={12} xs={12} >
                        <TextField fullWidth label="Correo electronico"
                          required
                          variant="outlined"
                          helperText="Correo electronico"
                          type="email"
                          {...register('email', { required: true })}
                        />
                      </Grid>
                    </React.Fragment>
                  )}
                </Grid>
            </Grid>
            {userSet && (<Grid
              item
              md={6}
              xs={12}
            >
              <Typography className={classes.textForm} variant="h6" id="tableTitle">
                Fecha registro: {dateRegis}
              </Typography>
            </Grid>)}
          </Grid>
          )}
        </CardContent>
        <Divider />
        <CardActions>
        {userSet ? (
          <Button className={classes.saveButton} variant="contained" type='submit'> Editar </Button>
        ) : (
          <Button className={classes.saveButton} type="button" variant="contained" onClick={() => handleBlur()} > { buttonText } </Button>
        ) }
        </CardActions>
      </form>
    </Card>
  );
};

EditForm.propTypes = {
  className: PropTypes.string,
  profile: PropTypes.object.isRequired
};

export default EditForm;
