import React from 'react'
import icon1 from '../assets/IconLand1.png'
import icon2 from '../assets/IconLand2.png'
import icon3 from '../assets/IconLand3.png'
import {
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#E0E5E3',
    paddingTop: '5rem',
    paddingBottom: '6rem',
  },
  content:{
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'start',
    padding: '1.75rem',
  },
  subContent:{
    flexDirection: 'column',
  },
  contentTopic:{
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'end',
    padding: '5rem 3.5rem',
  },
  topic:{
    borderRadius: '17px',
    backgroundColor: '#51E367',
    boxShadow: '0px 15px 6px #00000029',
    padding: '0.5rem 1.5rem',
    color: '#0A5359',
  },
  title:{
    fontSize: '1.5rem',
    fontWeight: 'bold',
    color: '#0A5359',
    marginBottom: '2rem',
  },
  text:{
    color: '#0A5359',
    fontSize: '1rem',
    textAlign: 'left',
  }
}));

export default function Services() {
  const classes = useStyles();
  return (
    <section className={classes.root}>
      <Grid container>
        <Grid item md={12} xs={12} className={classes.contentTopic + ' serviceContent'} id='services'>
          <h1 className={classes.topic +' serviceTitle'}>SERVICIOS MOVILPLATA</h1>
        </Grid>
        <Grid item md={4} xs={12} className={classes.content}>
          <img src={icon1}  width='100' alt='movilplata-logo'/>
          <div className={classes.subContent}>
            <h1 className={classes.title}>Bonos Dirigidos</h1>
            <p className={classes.text}>
              Nuestra plataforma 
              transaccional, es emisora de 
              bonos digitales (Movilbonos), 
              los cuales puedes usar como 
              método de pago para redimir 
              en compras, abonar y/o pagar 
              facturas en nuestros comercios aliados.
            </p>
          </div>
        </Grid>
        <Grid item md={4} xs={12} className={classes.content}>
          <img src={icon2}  width='100' alt='movilplata-logo'/>
          <div className={classes.subContent}>
            <h1 className={classes.title}>Billetera Virtual</h1>
            <p className={classes.text}>
              Con Movilplata puedes 
              realizar múltiples 
              operaciones financieras. 
              Recibe, transfiere, paga y 
              recarga desde cualquier lugar.
            </p>
          </div>
        </Grid>
        <Grid item md={4} xs={12} className={classes.content}>
          <img src={icon3}  width='100' alt='movilplata-logo'/>
          <div className={classes.subContent}>
            <h1 className={classes.title}>Organiza tus finanzas</h1>
            <p className={classes.text}>
              Con nuestros bolsillos de 
              alimentación, transporte, 
              combustible, vestuario y 
              salud puedes destinar de 
              manera inteligente tu dinero.
            </p>
          </div>
        </Grid>
      </Grid>
    </section>
  )
}
