import React, { useState } from "react";
import Loader from "react-loader-spinner";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { Redirect } from "react-router-dom";
import { useAuth } from "../../../contexts/Auth";
import LoginService from "../../../services/AuthService";
import { makeStyles } from "@material-ui/styles";

const useStyles = makeStyles((theme) => ({
    content: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        textAlign: "center",
        flexDirection: "column",
    },
    form: {
        width: "55%",
        marginTop: "1rem",
    },
    title: {
        fontWeight: "bold",
        color: "#0A5359",
        borderBottom: "3px solid #0A5359",
    },
    text: {
        color: "#0A5359",
        fontSize: "18px",
    },
    botonMain: {
        backgroundColor: "transparent",
        borderRadius: "10px",
        border: "3px solid white",
        width: "100%",
        padding: "10px",
        color: "#0A5359",
        fontWeight: "Bold",
        fontSize: "1rem",
    },
    botonSecond: {
        backgroundColor: "#0A5359",
        borderRadius: "10px",
        width: "100%",
        padding: "10px",
        color: "white",
        fontWeight: "Bold",
        fontSize: "1rem",
        border: "unset",
    },
}));

const toastError = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
};
const FormLogin = (props) => {
    const classes = useStyles();
    const userData = localStorage.getItem("tokens");
    const [isLoading, setLoading] = useState(false);
    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();
    const { setAuthTokens, authTokens } = useAuth();

    const onSubmit = (data) => {
        setLoading(true);
        let loginService = new LoginService();
        loginService.getToken(data).then((response) => {
            if (response.code === 200) {
                loginService.loginApp(data).then((res) => {
                    if (res.description === "Success") {
                        localStorage.setItem("token", response.data);
                        localStorage.setItem("MpTkn", res.data.token);
                        loginService.getUserData(data).then((response) => {
                            if (
                                response.data.description === "Success" &&
                                response.data.data.commerce != null
                            ) {
                                setAuthTokens(response.data.data);
                                props.handleLoading(false);
                                toast.success("Logueado Correctamente !");
                                // window.location.reload();
                                setLoading(false);
                            } else {
                                if (response.data.data.commerce === null) {
                                    props.handleLoading(false);
                                    toast.error(
                                        "No tienes un comercio asociado.",
                                        toastError
                                    );
                                } else {
                                    props.handleLoading(false);
                                    toast.error(
                                        response.description,
                                        toastError
                                    );
                                }
                                setLoading(false);
                            }
                        });
                    } else {
                        setLoading(false);
                        props.handleLoading(false);
                        toast.error("Usuario no autorizado", toastError);
                    }
                });
            } else {
                setLoading(false);
                props.handleLoading(false);
                toast.error("Usuario no autorizado", toastError);
            }
        });
    };

    if (authTokens) {
        return <Redirect to="/admin/home" />;
    }

    const buttonText = isLoading ? (
        <Loader type="ThreeDots" color="#25d366" height={20} width={20} />
    ) : (
        "ENTRAR"
    );

    return (
        <div className={classes.content}>
            <h1 className={classes.title}>¡Hola!</h1>
            <p className={classes.text}>
                INGRESA A TÚ<br></br>BILLETERA VIRTUAL
            </p>
            <form
                onSubmit={handleSubmit(onSubmit)}
                name="loginForm"
                className={classes.form}
            >
                <div className="form-group">
                    <input
                        className={`form-control ${
                            errors.username && "is-invalid"
                        }`}
                        placeholder="CC"
                        name="username"
                        {...register("username", { required: true })}
                    />
                    {errors.username && (
                        <p className="invalid-feedback d-block">
                            Campo requerido!
                        </p>
                    )}
                </div>
                <div className="form-group">
                    <input
                        type="password"
                        className={`form-control ${
                            errors.password && "is-invalid"
                        }`}
                        placeholder="Contraseña"
                        name="password"
                        {...register("password", { required: true })}
                    />
                    {errors.password && (
                        <p className="invalid-feedback d-block">
                            Campo requerido!
                        </p>
                    )}
                </div>
                <div className="text-center">
                    <button type="submit" className={classes.botonMain}>
                        {buttonText}
                    </button>
                </div>
                <div className="form-group my-2">
                    {/* <button
                        className={classes.botonSecond}
                        onClick={props.modifyState}
                    >
                        Registro para comercios
                    </button> */}
                </div>
            </form>
        </div>
    );
};

export default FormLogin;
