import React, { useEffect, useMemo, useState } from "react";
import ClearIcon from "@material-ui/icons/Clear";
import {
    Container,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from "@material-ui/core";
import "./../styles/withdrawalAndPayments.css";
import moment from "moment";
import { utils, write } from "xlsx";
import { get_balances_clients } from "../../../../services/BalancesClients";

const BalancesClientsTableList = (props) => {
    const { data } = props;
    function createData(
        documento,
        nombre,
        apellido,
        bolsillo,
        saldo
    ) {
        return {
            documento,
            nombre,
            apellido,
            bolsillo,
            saldo
        };
    }

    const rows = useMemo(() => {
        return (
            data?.map((register) => {
                return createData(
                    register.check_current_balance.documento,
                    register.check_current_balance.nombre,
                    register.check_current_balance.apellido,
                    register.check_current_balance.bolsillo,
                    register.check_current_balance.saldo,
                );
            }) ?? []
        );
    }, [data]);

    return (
        <TableContainer component={Paper}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <b>DOCUMENTO</b>
                        </TableCell>
                        <TableCell align="center">
                            <b>NOMBRE</b>
                        </TableCell>
                        <TableCell align="center">
                            <b>APELLIDO</b>
                        </TableCell>
                        <TableCell align="center">
                            <b>BOLSILLO</b>
                        </TableCell>
                        <TableCell align="right">
                            <b>SALDO</b>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, idx) => (
                        <TableRow
                            key={idx}
                            sx={{
                                "&:last-child td, &:last-child th": {
                                    border: 0,
                                },
                            }}
                        >
                            <TableCell component="th" scope="row">
                                {row.documento}
                            </TableCell>
                            <TableCell align="center" component="th" scope="row">
                                {row.nombre}
                            </TableCell>
                            <TableCell align="center">
                                {row.apellido}
                            </TableCell>
                            <TableCell align="center">{row.bolsillo}</TableCell>
                            <TableCell align="right">{row.saldo}</TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    );
};

const BalancesClients = (props) => {
    const [data, setData] = useState([]);
    const [searchValue, setSearchValue] = useState("");

    // const onChangeSearchValue = (event) => {
    //     event.preventDefault();
    //     setSearchValue(event.target.value);
    // };

    const dataFiltered = useMemo(() => {
        return data.filter((register) => {
            const searchValueLowerCase = searchValue.toLowerCase().trim();
            const hasName = register.check_current_balance.nombre
                .toLowerCase()
                .includes(searchValueLowerCase);
            const hasLastName = register.check_current_balance.apellido
                ?.toLowerCase()
                .includes(searchValueLowerCase);
            const hasDocument = register.check_current_balance.documento
                ?.toString()
                .toLowerCase()
                .includes(searchValueLowerCase);
            const hasBolsillo = register.check_current_balance.bolsillo
                ?.toString()
                .toLowerCase()
                .includes(searchValueLowerCase);
            if (
                [
                    hasName,
                    hasLastName,
                    hasDocument,
                    hasBolsillo,
                ].some((bool) => bool === true)
            ) {
                return register;
            } else return null;
        });
    }, [searchValue, data]);

    useEffect(() => {
        get_balances_clients()
            .then((response) => {
                setData(response.data);
            })
            .catch((err) => {
                console.log(err);
            });
    }, []);

    const createArrayExcel = () => {
        const workbook = utils.book_new();
        const dataMapped = data.map(({check_current_balance}) => {return {...check_current_balance}});
        const spreadSheet = utils.json_to_sheet(dataMapped);
        utils.book_append_sheet(workbook, spreadSheet, "saldos de los clientes");
        const excelFile = write(workbook, { bookType: "xlsx", type: "binary" });

        function s2ab(s) {
            const buf = new ArrayBuffer(s.length);
            const view = new Uint8Array(buf);
            for (let i = 0; i < s.length; i++) {
                view[i] = s.charCodeAt(i) & 0xff;
            }
            return buf;
        }

        const blob = new Blob([s2ab(excelFile)], {
            type: "application/octet-stream",
        });

        const url = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.href = url;
        link.download = "saldos_clientes" + moment().format("l") + ".xlsx";
        link.click();

        URL.revokeObjectURL(url);
    };

    // let column = 4;
    return (
        <div>
            <div className="WAPContainerActions">
                <IconButton
                    aria-label="settings"
                    onClick={() => props.handleClick()}
                >
                    <ClearIcon />
                </IconButton>
                <div className="WAPContainerActionsRight">
                    {/* <TextField
                        value={searchValue}
                        onChange={onChangeSearchValue}
                        placeholder="Buscar"
                    /> */}
                    <button
                        disabled={!data.length}
                        className="WAPContainerActionsRightButton"
                        onClick={createArrayExcel}
                    >
                        Descargar Excel
                    </button>
                </div>
            </div>
            <Container maxWidth="lg">
                <BalancesClientsTableList data={dataFiltered} />
                {!data.length && (
                    <center>
                        <br/>
                        <p>Cargando..</p>
                    </center>
                )}
            </Container>
        </div>
    );
};

export default BalancesClients;
