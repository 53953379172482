import React from 'react'
import LogoMv from '../assets/Logo-Movilplata-web.png'
import AnchorLink from 'react-anchor-link-smooth-scroll';
import {
  Link
} from "react-router-dom";

const showTab = () => {
  const x = document.getElementById('container-box')
  if (x.style.display === "none") {
    x.style.display = "block";
  } else {
    x.style.display = "none";
  }
}

export default function Navbar() {
  return (
    <header style={{backgroundColor: '#ECEFEE'}}>
      <nav>
        <img src={LogoMv} className="logo" width='250' alt='movilplata-logo' style={{position: 'absolute', left: '5%', top: '10px'}}/>
        <div className="links">
          <AnchorLink href='#inicio' offset='70'>INICIO</AnchorLink>
          <AnchorLink href='#services' offset='70'>SERVICIOS</AnchorLink>
          <AnchorLink href='#download' offset='70'>DESCARGA APP</AnchorLink>
          <AnchorLink href='#experience' offset='70'>EXPERIENCIA</AnchorLink>
          <AnchorLink href='#contact' offset='70'>CONTACTANOS</AnchorLink>
        </div>
        <div className="nav-buttons">
          <Link to='/login' className='nav-btn'>Ingresa</Link>
        </div>
        <div className="burguer-btn" onClick={ () => showTab() }>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </nav>

      <div className="link-sm-container" id='container-box'>
        <AnchorLink href='#inicio' offset='70' onClick={ () => showTab() }>INICIO</AnchorLink>
        <AnchorLink href='#services' offset='70' onClick={ () => showTab() }>SERVICIOS</AnchorLink>
        <AnchorLink href='#download' offset='70' onClick={ () => showTab() }>DESCARGA APP</AnchorLink>
        <AnchorLink href='#experience' offset='70' onClick={ () => showTab() }>EXPERIENCIA</AnchorLink>
        <AnchorLink href='#contact' offset='70' onClick={ () => showTab() }>CONTACTANOS</AnchorLink>

        <Link to='/login' className='nav-btn'>Ingresa</Link>
      </div>
    </header>
  )
}
