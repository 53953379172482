import React from 'react'
import {
  Link
} from "react-router-dom";
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#0A5359',
    paddingTop: '3rem',
    paddingBottom: '1rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    textAlign: 'center',
  },
  topic:{
    borderRadius: '50px',
    backgroundColor: '#FFFFFF',
    padding: '1.5rem',
    color: '#0A5359',
    fontWeight: 'bold',
  },
  title:{
    fontSize: '2rem',
    color: '#DFEDEE',
    marginBottom: '0rem'
  },
  text:{
    color: '#DFEDEE',
    fontSize: '1rem',
  }
}));

export default function Contact() {
  const classes = useStyles();
  return (
    <section className={classes.root} id='contact'>
      <h3 className={classes.topic}>soporte@movilplata.com</h3>
      <p className={classes.title}>
        Whatsapp
      </p>
      <p className={classes.title}>
        3176428837
      </p>
      <p className={classes.text}>
        Registrandote, estas indicando que aceptas nuestros <Link to='/terms'><a>Terminos de uso</a></Link> y <Link to='/privacy'><a>la politica de privacidad</a></Link>.
      </p>
      <p className={classes.text}>
        MovilPlata 2023 | Privacy Policy | Dirección: Vía 40#71-124 Ciudad: Barranquilla | Correo de contacto: soporte@movilplata.com
      </p>
    </section>
  )
}
