import React from "react";

import EditForm from "./components/EditForm";
import EditPassForm from "./components/EditPassForm";
import Transactions from "./components/Transactions";
import WithdrawalAndPayments from "./components/WithdrawalAndPayments";
import BalancesClients from "./components/BalancesClients";
import InsertMassiveUsers from "./components/InsertMassieveUsers";

const ModalPanel = (props) => {
    const userData =
        typeof props.userData === "string"
            ? JSON.parse(props.userData)
            : props.userData;
    return (
        <React.Fragment>
            {props.content === "edit" && (
                <EditForm
                    userData={userData}
                    handleClick={() => props.handleClick()}
                />
            )}
            {props.content === "transactions" && (
                <Transactions
                    userData={userData}
                    handleClick={() => props.handleClick()}
                />
            )}
            {props.content === "editPass" && (
                <EditPassForm
                    userData={userData}
                    handleClick={() => props.handleClick()}
                />
            )}
            {props.content === "withdrawallAndPayments" && (
                <WithdrawalAndPayments
                    userData={userData}
                    handleClick={() => props.handleClick()}
                />
            )}
            {props.content === "balancesClients" && (
                <BalancesClients
                    userData={userData}
                    handleClick={() => props.handleClick()}
                />
            )}
            {props.content === "insertMassiveUsers" && (
                <InsertMassiveUsers
                    userData={userData}
                    handleClick={() => props.handleClick()}
                />
            )}
        </React.Fragment>
    );
};
//: props.content === 'retiro' ? <RetiroForm sucursales={props.sucursales} userData={userData} handleClick={() => props.handleClick()}/>
export default ModalPanel;
