import React from 'react'
import {
  Link
} from "react-router-dom";
import landImage from '../assets/fondo-land.png'
import slideImage1 from '../assets/Inter1.png'
import slideImage2 from '../assets/Inter2.png'
import slideImage3 from '../assets/Inter3.png'
import slideImage4 from '../assets/Inter4.png'
import slideImage5 from '../assets/Inter5.png'
import {
  Grid,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: '#FFFFFF',
    paddingTop: '5rem',
    paddingBottom: '6rem',
  },
  content:{
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
    justifyContent: 'end',
    padding: '5%',
  },
  carousel:{
    backgroundImage: 'linear-gradient(180deg, #48E88B, #59C99F)',
    paddingTop: '6rem',
    paddingBottom: '6rem',
    paddingLeft: '4rem',
    paddingRight: '1rem',
    borderRadius: '25px',
  },
  contentImage:{
    display: 'flex',
    justifyContent: 'center',
  },
  title:{
    fontWeight: 'bold',
    color: '#0A5359',
  },
  text:{
    color: '#393B3B',
    fontSize: '18px',
  }
}));

export default function Experience() {
  const classes = useStyles();
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };  
  return (
    <section className={classes.root} id='experience'>
      <Grid container>
        <Grid item md={4} xs={12} className={classes.content}>
          <h1 className={classes.title}>EXPERIENCIA MOVILPLATA</h1>
          <p className={classes.text}>Con Movilplata podrás manejar y visualizar tus bonos y dinero de manera fácil y organizada. Puedes modificar, personalizar tu perfil y revisar tus movimientos de manera detallada.</p>
        </Grid>
        <Grid item md={8} xs={12} className={classes.carousel}>
          <Carousel responsive={responsive}>
            <div className={classes.contentImage}>
              <img src={slideImage1} width={'90%'}/>
            </div>
            <div className={classes.contentImage}>
              <img src={slideImage2} width={'90%'}/>
            </div>
            <div className={classes.contentImage}>
              <img src={slideImage3} width={'90%'}/>
            </div>
            <div className={classes.contentImage}>
              <img src={slideImage4} width={'90%'}/>
            </div>
            <div className={classes.contentImage}>
              <img src={slideImage5} width={'90%'}/>
            </div>
          </Carousel>
        </Grid>
      </Grid>
    </section>
  )
}
