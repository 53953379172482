import React from "react";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import CssBaseline from "@material-ui/core/CssBaseline";
import Divider from "@material-ui/core/Divider";
import Drawer from "@material-ui/core/Drawer";
import Hidden from "@material-ui/core/Hidden";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import MenuIcon from "@material-ui/icons/Menu";
import Toolbar from "@material-ui/core/Toolbar";
import { makeStyles, ThemeProvider } from "@material-ui/core/styles";
import LogoImage from "../../assets/image/admin/logoAdmin.png";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import AccountCircle from "@material-ui/icons/AccountCircle";
import { Link } from "react-router-dom";
import theme from "../../theme/index";
import {
    Home,
    HomeWork,
    ViewList,
    Payment,
    Loyalty,
    AttachMoney,
} from "@material-ui/icons";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";
import { useAuth } from "../../contexts/Auth";
import {
    disabledAdministrationPage,
    disabledAdminPermision,
} from "../../helpers/disabledAdminPermission";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
    root: {
        display: "flex",
        flexGrow: 1,
    },
    drawer: {
        [theme.breakpoints.up("sm")]: {
            width: drawerWidth,
            flexShrink: 0,
        },
    },
    appBar: {
        [theme.breakpoints.up("sm")]: {
            zIndex: theme.zIndex.drawer + 1,
            marginLeft: drawerWidth,
        },
    },
    menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
            display: "none",
        },
    },
    toolbar: theme.mixins.toolbar,
    drawerPaper: {
        width: drawerWidth,
    },
    content: {
        flexGrow: 1,
        padding: theme.spacing(3),
    },
    buttonLogin: {
        marginLeft: "auto",
    },
    iconCircle: {
        color: "#fff",
    },
}));

function Layout({ children }, props) {
    const { authTokens, setAuthTokens } = useAuth();
    const { container } = props;
    const classes = useStyles();
    const [mobileOpen, setMobileOpen] = React.useState(false);

    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const logout = () => {
        setAnchorEl(null);
        setAuthTokens();
        localStorage.clear();
    };

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const renderSwitch = (param) => {
        switch (param) {
            case "1":
                return <Home color="primary" />;
            case "2":
                return <HomeWork color="primary" />;
            case "3":
                return <ViewList color="primary" />;
            case "4":
                return <Payment color="primary" />;
            case "5":
                return <Loyalty color="primary" />;
            case "6":
                return <AttachMoney color="primary" />;
            case "7":
                return <SupervisorAccountIcon color="primary" />;
            default:
                return "foo";
        }
    };

    const hasRole = (permision) => {
        const userRoles = authTokens.userRoles;
        // console.log(userRoles)
        return userRoles.find((el) => el.id === permision);
    };

    const Options = [
        {
            name: "Home",
            url: "/admin/home",
            icon: "1",
            disabled: false,
            permision: true,
        },
        {
            name: "Crear sucursal",
            url: disabledAdminPermision() ? "" : "/admin/sucursal/create",
            icon: "2",
            disabled: disabledAdminPermision(),
            permision: !disabledAdminPermision(),
        },
        {
            name: "Listado de sucursales",
            url: disabledAdminPermision() ? "" : "/admin/sucursal/index",
            icon: "3",
            disabled: disabledAdminPermision(),
            permision: !disabledAdminPermision(),
        },
        {
            name: "POS",
            url: disabledAdminPermision() ? "" : "/admin/pos",
            icon: "4",
            disabled: disabledAdminPermision(),
            permision: !disabledAdminPermision(),
        },
        {
            name: "Administración",
            url: disabledAdminPermision() ? "" : "/admin/gestion",
            icon: "7",
            disabled: disabledAdminPermision(),
            // permision: hasRole(4),
            permision:
                !disabledAdminPermision() && !disabledAdministrationPage(),
        },
        {
            name: "Fidelizacion",
            url: "/admin/",
            icon: "5",
            disabled: true,
            permision: false,
        },
        {
            name: "Reporte Diario",
            url: "/admin/reports/daily",
            icon: "6",
            disabled: false,
            permision: true,
        },
        //{name: 'Recarga Empresarial', url:'/admin/recharge', icon: '6', disabled: false }
    ];
    const drawer = (
        <div>
            <div className={classes.toolbar} />
            <Divider />
            <List>
                {Options.map((hash, index) => {
                    if (hash.permision) {
                        return (
                            <ListItem
                                button
                                component={Link}
                                to={hash.url}
                                key={index}
                                disabled={hash.disabled}
                            >
                                <ListItemIcon>
                                    {renderSwitch(hash.icon)}
                                </ListItemIcon>
                                <ListItemText primary={hash.name} />
                            </ListItem>
                        );
                    }
                })}
            </List>
        </div>
    );

    return (
        <ThemeProvider theme={theme}>
            <div className={classes.root}>
                <CssBaseline />
                <AppBar position="fixed" className={classes.appBar}>
                    <Toolbar>
                        <IconButton
                            color="inherit"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            className={classes.menuButton}
                        >
                            <MenuIcon />
                        </IconButton>
                        <img
                            src={LogoImage}
                            className={classes.title}
                            alt="..."
                            style={{ width: "14em" }}
                        />
                        <IconButton
                            aria-label="account of current user"
                            aria-controls="menu-appbar"
                            aria-haspopup="true"
                            onClick={handleClick}
                            color="inherit"
                            className={classes.buttonLogin}
                        >
                            <AccountCircle />
                        </IconButton>
                        <Menu
                            id="simple-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleClose}
                        >
                            <MenuItem
                                component={Link}
                                to={"/admin/changePass"}
                                onClick={handleClose}
                            >
                                Cambiar contraseña
                            </MenuItem>
                            <MenuItem onClick={logout}>Cerrar sesión</MenuItem>
                        </Menu>
                    </Toolbar>
                </AppBar>
                <nav className={classes.drawer} aria-label="movilplata">
                    {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
                    <Hidden smUp implementation="css">
                        <Drawer
                            container={container}
                            variant="temporary"
                            anchor={
                                theme.direction === "rtl" ? "right" : "left"
                            }
                            open={mobileOpen}
                            onClose={handleDrawerToggle}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                        >
                            {drawer}
                        </Drawer>
                    </Hidden>
                    <Hidden xsDown implementation="css">
                        <Drawer
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            variant="permanent"
                            open
                        >
                            {drawer}
                        </Drawer>
                    </Hidden>
                </nav>
                <main className={classes.content}>
                    <div className={classes.toolbar} />
                    {children}
                </main>
            </div>
        </ThemeProvider>
    );
}

Layout.propTypes = {
    /**
     * Injected by the documentation to work in an iframe.
     * You won't need it on your project.
     */
    container: PropTypes.any,
};

export default Layout;
