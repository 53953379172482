import axios from "axios";

export const insert_massive_users = async (users, headers) => {
    const result = await axios
        .post(
            `https://intranet.eticosweb.net/movilplata-backend/api/v1/erp/Usuarios/usuariosMasivos`,
            {
                clientes: users,
            },
            {
                headers: {
                    ...headers,
                    "x-api-key":
                        "a43669e26f4e4944a1920f67a4ec12d5c740db868800261f6af3a7d691543c303ec72e3e",
                },
            }
        )
        .then((response) => {
            return { status: response.status, data: response.data };
        })
        .catch((err) => {
            return { status: 500, err };
        });

    return result;
};
