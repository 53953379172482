import React, { Component, Fragment } from 'react'
import Navbar from './components/Navbar'
import SlideShow from './components/SlideShow'
import Services from './components/Services'
import Download from './components/Download'
import Experience from './components/Experience'
import Contact from './components/Contact'

export default class index extends Component {
  render() {
    return (
      <Fragment>
        <Navbar />
        <SlideShow />
        <Services />
        <Download />
        <Experience />
        <Contact />
      </Fragment>
    )
  }
}
