import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import SucursalService from '../../../services/SucursalService'
import {
  Checkbox,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Input,
  InputAdornment ,
  lighten,
  makeStyles,
  Paper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  Toolbar,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import {
  useHistory
} from "react-router-dom";

const toastError = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true
}

function createData(name, city, address, email, id) {
  return { name, city, address, email, id, selected: false };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map(el => el[0]);
}

const headCells = [
  { id: 'name', numeric: false, disablePadding: false, label: 'Nombre de la sucursal', minWidth: 300 },
  { id: 'city', numeric: false, disablePadding: false, label: 'Ciudad', minWidth: 100 },
  { id: 'email', numeric: false, disablePadding: false, label: 'Email', minWidth: 170 },
  { id: 'address', numeric: false, disablePadding: false, label: 'Direccion', minWidth: 500 },
];

function EnhancedTableHead(props) {
  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = property => event => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map(headCell => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
            // style={{ minWidth: headCell.minWidth }}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
        <TableCell
        key={'actions'}
        >
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles(theme => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  }
}));

const EnhancedTableToolbar = props => {
  const classes = useToolbarStyles();
  const { numSelected, search, changeFilter } = props;
  const Items = props.ItemSelected

  const handleDelete = () => {
    alert(Items);
  }

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1">
          {numSelected} seleccionados.
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle">
          Listado de sucursales.
        </Typography>
      )}

        <Input
          value={search}
          onChange={(event)=>changeFilter(event.target.value)}
          placeholder="Buscar..."
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
        />

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton aria-label="delete">
            <DeleteIcon onClick={() => handleDelete()} />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  progress: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5em'
  }
}));

const ListSucursal = props => {
  const classes = useStyles();
  const userData = props.userData ? props.userData : {}
  let currentComerceFetch = userData ? userData.commerce : {}
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense, setDense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [ sucursales, setSucursales ] = React.useState([])
  const [ sucursalesFull, setSucursalesFull ] = React.useState([])
  const [filter, setFilter] = React.useState('');
  const [ isLoading, setLoading ] = React.useState(true)
  let history = useHistory();
  let sucursalService = new SucursalService()
  useEffect(() => {
    serchBranch();
  },[])

  useEffect(() => {
    // console.log(sucursales.filter(br=>filtro(br)))
    setSucursales(sucursalesFull.filter(br=>filtro(br)))
  },[filter])

  const filtro = (branch) =>{
    const name = branch.name
    return name.toUpperCase().indexOf(filter.toUpperCase())>-1
  }

  const serchBranch = () =>{
    sucursalService.ListService(currentComerceFetch, userData).then((response) => {
      if(response.data.code === 500) {
        toast.error('Error. Contacta a el administrador.', toastError);
        localStorage.removeItem("tokens");
      } else if (response.data.code === 401) {
        toast.error('Usuario no autorizado.', toastError);
        setLoading(false);
        redirectToHome();
      } else if (response.statusCode === 200) {
        const rowsData = response?.data ?? [];
        const rows = rowsData.map(( sucursal ) => {
          if(sucursal != null){
            let sucursal_name = sucursal.name != null ? sucursal.name : '';
            let sucursal_city = sucursal.city != null ? sucursal.city.name : '';
            let sucursal_address = sucursal.address != null ?  sucursal.address : '';
            let sucursal_email = sucursal.email != null ? sucursal.email : '';
            let sucursal_id = sucursal.id != null ? sucursal.id : '';
            return createData(sucursal_name, sucursal_city, sucursal_address, sucursal_email, sucursal_id);
          }else{
            return createData('invalid name', 'invalid city', 'invalid address', 'invalid email', 'invalid id')
          }
        })
        setSucursalesFull(rows)
        setSucursales(rows)
        setFilter('')
        setLoading(false)
      } else {
        toast.error('Usuario no autorizado.', toastError);
        setLoading(false);
        redirectToHome();
      }
    })
  }

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = event => {
    if (event.target.checked) {
      const newSelecteds = sucursales.map(n => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const redirectToHome = () => {
    history.push('/Admin/home');
  }

  const deleteBranch = (idBranch) => {
    if(window.confirm("¿Desea elimnar esta sucursal?")){
      setLoading(true);
      sucursalService.DeleteSucursalService(idBranch, currentComerceFetch.id, userData).then((response) => {
        // console.log(response)
        if(response.statusCode === 500) {
          toast.error('Error. Contacta a el administrador.', toastError);
          localStorage.removeItem("tokens");
        } else if (response.statusCode === 401) {
          toast.error('Usuario no autorizado.', toastError);
          setLoading(false);
          redirectToHome();
        } else {
          // console.log(response.data);
          serchBranch();
        }
      })
    }
  }

  const handleClick = (id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = event => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleChangeDense = event => {
    setDense(event.target.checked);
  };


  const isSelected = id => selected.indexOf(id) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, sucursales.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar numSelected={selected.length} ItemSelected={selected} search={filter} changeFilter={value=>setFilter(value)} />
        <TableContainer className={isLoading ? classes.progress : ''}>
          {isLoading ? (
            <CircularProgress/>
          ) : (
            <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={dense ? 'small' : 'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={sucursales.length}
            />
            <TableBody>
              {stableSort(sucursales, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${row.id}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.name}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                          onChange={() => handleClick(row.id)}
                        />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        {row.name}
                      </TableCell>
                      <TableCell align="left">{row.city}</TableCell>
                      <TableCell align="left">{row.email}</TableCell>
                      <TableCell align="left">{row.address}</TableCell>
                      <TableCell align="left">
                        <IconButton href={'/admin/sucursal/edit/'+row.id} size='small'><EditIcon/></IconButton >
                        <IconButton onClick={()=>{deleteBranch(row.id)}} size='small'><DeleteIcon/></IconButton >
                      </TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (dense ? 33 : 53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
          )}
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={sucursales.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </Paper>
      <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Filas Ajustadas"
      />
    </div>
  );
}

export default ListSucursal;